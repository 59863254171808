import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isURL from 'validator/lib/isURL';
import { SetDomainHeader } from "../../../Store/DomainData";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Stepperformhead from "./Stepperformhead";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import Select from "react-select";
import { FaEye, FaEyeSlash } from "react-icons/fa";




function Domaindata() {
  const [formState, setFormState] = useState({
    webauthenticate: "",
    webtype: "",
    showResults: false,
    websiteName: "",
    webLink: "",
    onUsername: "",
    onPassword: "",
    errors: {
      webnameError: "",
      weblinkError: "",
      onUserError: "",
      onPasswordError: "",
      onPageError: "",
    },
  });

  const [showPasswordlgn, setShowPasswordlgn] = useState(false);


  const [loading, setLoading] = useState(false);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ApiToken = useSelector((state) => state.apiauthkey.value);


  const websiteoptions = [
    { value: "HTML", label: "HTML", name: "webtype" },
    { value: "JS", label: "JS", name: "webtype" }
  ];


  const webauthenticatestatus = [
    { value: "Yes", label: "Yes", name: "webauthenticate" },
    { value: "No", label: "No", name: "webauthenticate" }
  ]

  const handleClose = () => setApiOpen(false);
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const handleChange = (e) => {




    if (e.target) {
      const { name, value } = e.target;
      localStorage.setItem(e.target.name, e.target.value);

      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
        ...(name === "webauthenticate" && {
          showResults: value === "Yes",
          onUsername: value === "No" ? "" : prevState.onUsername,
          onPassword: value === "No" ? "" : prevState.onPassword,
        }),
        errors: { ...prevState.errors, [`${name}Error`]: "" },
      }));

    } else {

      console.log(e)

      const { name, value } = e;

      console.log(name, "asjdasd", value, "ajsdhsadk")

      localStorage.setItem(name, JSON.stringify(e));

      setFormState((prevState) => ({
        ...prevState,
        [name]: e,
        ...(name === "webauthenticate" && {
          showResults: value === "Yes",
          onUsername: value === "No" ? "" : prevState.onUsername,
          onPassword: value === "No" ? "" : prevState.onPassword,
        }),
        errors: { ...prevState.errors, [`${name}Error`]: "" },
      }));

    }



  };

  const validateForm = () => {
    const { websiteName, webLink, webauthenticate, onUsername, onPassword } = formState;
    let errors = {};
    let isValid = true;

    if (!websiteName) {
      errors.webnameError = "Enter Website Name";
      isValid = false;
    }

    if (!webLink) {
      errors.weblinkError = "Enter Link Address";
      isValid = false;
    } else if (!isURL(webLink)) {
      errors.weblinkError = "Enter Valid Link Address";
      isValid = false;
    }

    if (webauthenticate === "Yes") {
      if (!onUsername) {
        errors.onUserError = "Enter Username";
        isValid = false;
      }
      if (!onPassword) {
        errors.onPasswordError = "Enter Password";
        isValid = false;
      }
    }

    setFormState((prevState) => ({ ...prevState, errors }));
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    setLoading(true);

    const { websiteName, webLink, webtype, webauthenticate, onUsername, onPassword } = formState;
    const domainData = {
      domain_name: websiteName,
      domain_url: webLink,
      domain_type: webtype.value,
      domain_has_http: webauthenticate.value === "Yes",
      domain_http_username: onUsername,
      domain_http_password: onPassword,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}domain/add_domain/`, domainData, {
        headers: { Authorization: ApiToken },
      });

      if (response?.data?.status_code === 200) {
        dispatch(SetDomainHeader({ header_selected: response.data.user_domain_id }));
        window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
      }
    } catch (error) {
      const status = error?.response?.status;
      if ([599, 500, 400, 404].includes(status)) {
        setApiErrorMsg(error?.response?.data?.error);
        setApiOpen(true);
      } else if (error?.code === "ERR_NETWORK") {
        navigate('/errorpages');
      } else {
        setFormState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, onPageError: error.response.data.error },
        }));
      }
    } finally {
      setLoading(false);
    }
  };

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {

        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        // localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      // localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };


  const handleTogglePasswordlgn = () => {
    setShowPasswordlgn(!showPasswordlgn);
  };

  useEffect(() => {
    if (localStorage.getItem("websiteName")) {
      setFormState(prevState => ({
        ...prevState,
        websiteName: localStorage.getItem("websiteName")
      }));
    }


    if (localStorage.getItem("webLink")) {
      setFormState(prevState => ({
        ...prevState,
        webLink: localStorage.getItem("webLink")
      }));
    }

    if (localStorage.getItem("webtype")) {
      setFormState(prevState => ({
        ...prevState,
        webtype: JSON.parse(localStorage.getItem("webtype"))
      }));
    }


    if (localStorage.getItem("webauthenticate")) {
      setFormState(prevState => ({
        ...prevState,
        webauthenticate: JSON.parse(localStorage.getItem("webauthenticate")),
        showResults: JSON.parse(localStorage.getItem("webauthenticate")).value === "Yes" ? true : false
      }));



    }

    if (localStorage.getItem("onUsername")) {
      setFormState(prevState => ({
        ...prevState,
        onUsername: localStorage.getItem("onUsername")
      }));
    }


    if (localStorage.getItem("onPassword")) {
      setFormState(prevState => ({
        ...prevState,
        onPassword: localStorage.getItem("onPassword")
      }));
    }


  }, [])


  const { websiteName, webLink, webtype, webauthenticate, showResults, errors } = formState;


  console.log("formState", formState);

  return (
    <>

      <div className="userdata">
        <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px", background: "#fff", position: "fixed", zIndex: 999 }}>
          {/* Logo at the start */}
          <img
            className="Eso_logo"
            src={logoimage}
            alt="logo"
            style={{ width: "14%", background: "#fff" }}
          />

          {/* Group for the two buttons */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingRight: "20px" }}>
            <button
              onClick={handleToggle}
              className="faq_class"
              title="Frequently Asked Question"
              style={{ padding: "8px 13px 8px 7px" }}
            >
              <QuestionMarkIcon />
            </button>

            {/* FAQ Sidebar conditionally rendered */}
            {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

            <button onClick={TriggerLogout}>
              <span
                className="material-icons"
                style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
              >
                <LogoutIcon />
              </span>
            </button>
          </div>
        </div>

        <Stepperformhead setActiveStep={1} />

        <div className="outer-container"
          style={{
            width: "calc(100% - 60px)",
            marginRight: "60px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="userdata_inner">
            <h1>Please Share Your Website Details</h1>
            <p>This Helps Us Customize Your Experience</p>
            <span className="domain-errordata">{errors.onPageError}</span>

            <form className="frm_container">
              <table /*style={{ display: "grid", justifyContent: "center" }} */ >
                <tbody style={{ display: "grid" }}>
                  {/* Website Name */}
                  <tr className="form-group user_country websitename">
                    <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                      <label htmlFor="websiteName">Website Name:</label>
                      <input
                        id="websiteName"
                        title="This name is used as an alias to manage your domain."
                        type="text"
                        name="websiteName"
                        placeholder="Enter Website Name"
                        className="userdataselectdomain"
                        value={websiteName}
                        onChange={handleChange}
                        style={{ display: "block", marginTop: "5px", textAlign: "left" }}
                      />
                      <span className="domain-errordata">{errors.webnameError}</span>
                    </td>
                  </tr>

                  {/* Website Link */}
                  <tr className="form-group user_country websitename">
                    <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                      <label htmlFor="webLink">Website Link:</label>
                      <input
                        id="webLink"
                        type="text"
                        title="URL of your website (eg: www.example.com)"
                        name="webLink"
                        className="userdataselectdomain"
                        placeholder="Enter Website Link"
                        value={webLink}
                        onChange={handleChange}
                        style={{ display: "block", marginTop: "5px" }}
                      />
                      <span className="domain-errordata">{errors.weblinkError}</span>
                    </td>
                  </tr>

                  {/* Website Type */}
                  <tr className="form-group user_country websitename">
                    <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                      <label>Website Type:</label>
                      <Select
                        id="webtype"
                        name="webtype"
                        title="Select if your website is based on HTML or JavaScript."
                        value={webtype}
                        options={websiteoptions}
                        placeholder="Enter Website Type"
                        onChange={handleChange}
                        className="selectDomain"
                        classNamePrefix="selectDomain"

                        style={{ display: "block", marginTop: "5px" }}
                      />

                    </td>
                  </tr>

                  {/* Authentication Requirement */}
                  <tr className="form-group user_country websitename">
                    <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                      <label>Does your website need authentication to access?</label>
                      <Select
                        id="webauthenticate"
                        name="webauthenticate"
                        title="Does your website need HTTP authentication?"
                        value={webauthenticate}
                        onChange={handleChange}
                        options={webauthenticatestatus}
                        placeholder="Enter Yes or No"
                        style={{ display: "block", marginTop: "5px" }}
                        className="selectDom"
                        classNamePrefix="selectDom"
                      />

                    </td>
                  </tr>

                  {/* onUsername and onPassword (Conditional) */}
                  {showResults && (
                    <>
                      <tr className="form-group user_country websitename">
                        <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                          <label htmlFor="onUsername">Username:</label>
                          <input
                            type="text"
                            id="onUsername"
                            name="onUsername"
                            title="Username"
                            placeholder="Enter Username"
                            value={formState.onUsername}
                            onChange={handleChange}
                            style={{ display: "block", marginTop: "5px" }}
                          />
                          <span className="domain-errordata">{errors.onUserError}</span>
                        </td>
                      </tr>
                      <tr className="form-group user_country websitename">
                        <td colSpan="2" style={{ padding: "10px", textAlign: "left" }}>
                          <label htmlFor="onPassword">Password:</label>
                          <div className="password-container">
                            <input
                              type={showPasswordlgn ? "text" : "password"}
                              id="onPassword"
                              name="onPassword"
                              title="Password"
                              placeholder="Enter Password"
                              value={formState.onPassword}
                              onChange={handleChange}
                              style={{ display: "block", marginTop: "5px", paddingRight: "35px" }}  // Add padding to the right for button space
                            />
                            <button
                              type="button"
                              className="togglepwd"
                              onClick={handleTogglePasswordlgn}
                            >
                              {showPasswordlgn ? <FaEye className="FaEy" /> : <FaEyeSlash className="FaEyeSlash" />}
                            </button>
                          </div>
                          <span className="domain-errordata">{errors.onPasswordError}</span>
                        </td>
                      </tr>
                    </>
                  )}

                  {/* Next Button */}
                  {/* <tr className="form_next" style={{ display: "flex", justifyContent: "center" }}>
                    <td colSpan="2" style={{ textAlign: "center", paddingTop: "20px" }}>
                      <button className="nextbutton" type="button" onClick={submitForm} disabled={loading}>
                        Next
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/Userdata")}
                  sx={{
                    padding: "10px 10px",
                    borderRadius: "10px",
                    // fontWeight: "900",
                    fontSize: "16px",
                    backgroundColor: "#ff601f",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7d45" },
                  }}
                >
                 <FastRewindIcon/>Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  disabled={
                    !formState.websiteName ||
                    !formState.webLink ||
                    !formState.webtype ||
                    (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                  }
                  sx={{
                    marginLeft: "50px",
                    padding: "10px 15px",
                    borderRadius: "10px",
                    // fontWeight: "900",
                    fontSize: "16px",
                    backgroundColor:
                      !formState.websiteName || !formState.webLink || !formState.webtype ||
                        (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                        ? "#ccc"
                        : "#ff601f",
                    color:
                      !formState.websiteName || !formState.webLink || !formState.webtype ||
                        (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                        ? "#666"
                        : "white",
                    "&:hover": {
                      backgroundColor:
                        !formState.websiteName || !formState.webLink || !formState.webtype ||
                          (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                          ? "#ccc"
                          : "#ff7d45",
                    },
                  }}
                >
                  Next<FastForwardIcon style={{marginLeft:"-10px"}} />
                </Button>
              </Box>
            </form>




          </div>
        </div>


        <Dialog open={apiOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{apiErrorMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div >
    </>
  );
}

export default Domaindata;
