import React, { useState, useEffect, useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";


const UserExperienceSettings = () => {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);

  const [TotalPageLoadTime, SetTotalPageLoadTime] = useState(0);
  const [PageLoadTime, SetPageLoadTime] = useState(0);
  const [SampleSpeed, SetSampleSpeed] = useState(0);
  const [Url, Seturl] = useState("");
  const [UserExperienceSettingsErr, SetUserExperienceSettingsErr] =
    useState("");
  const [UpdateId, SetUpdateId] = useState("");
  const [Apdex, SetApdex] = useState("");
  const [loading, Setloading] = useState(false);

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
const [totalpageerror,SetTotalpageerror]=useState()
const [pageloaderror,SetPageloaderror]=useState()
const [seederror,SetSeederror]=useState()
const [uxscoreerror,SetUxscoreerror]=useState()
const [userExpSettingPlan, setuserExpSettingPlan] = useState({status: true, error: ""});


  const GetUserExperienceSettings = async () => {
    Setloading(true);
    SetTotalPageLoadTime(1);
    SetPageLoadTime(1);
    SetSampleSpeed(1);
    Seturl("");
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}settings/UxScoreSettings/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
          // console.log("kannan",error)
        });
      Setloading(false);

      if (datavalue?.data) {
        console.log("UxScoreSettings", datavalue.data[0]);
        SetTotalPageLoadTime(datavalue.data[0].tol_page_load_time);
        SetPageLoadTime(datavalue.data[0].page_load_time);
        SetSampleSpeed(datavalue.data[0].sample_seed);
        Seturl(datavalue.data[0].default_url);
        SetApdex(datavalue.data[0].apdex_score_metrics);
        SetUpdateId(datavalue.data[0].id);
      }
    } catch (err) {
      Setloading(false);
      console.log(err);
    }
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SaveSettings = async () => {
    SetUserExperienceSettingsErr("");
    SetTotalpageerror("")
    SetPageloaderror("")
    SetSeederror("")
    SetUxscoreerror("")
    var datavals=2;

    if(TotalPageLoadTime===0 || TotalPageLoadTime===""){
      SetTotalpageerror("Please fill the value between 1 to 12")

      datavals=1
    }
    if(PageLoadTime===0 || PageLoadTime===""){
      SetPageloaderror("Please fill the value between 1 to 12")
      datavals=1
    }
  if(SampleSpeed === 0 || SampleSpeed===""){
    SetSeederror("Please fill the value between 5 to 50")
    datavals=1
  }
  if( Url === "" ){
    SetUxscoreerror("Please enter default UX Score URL")
    datavals=1
  }
  else if(Url !==""){
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z0-9$._%+-]+)@)?' + // username:password@
      '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}))' + // domain name
      '(\\:[0-9]{1,5})?' + // port (optional)
      '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' + // path
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
      '(\\#[-a-zA-Z0-9_]*)?$' // fragment identifier (optional)
    );
    const isValidUrl = urlPattern.test(Url);
    if(!isValidUrl){
      SetUxscoreerror("Please enter valid UX Score URL")
      datavals=1
    }
     

  }
  
  if(SampleSpeed !== 0 || SampleSpeed!==""){
  if(!isNaN(SampleSpeed) && SampleSpeed < 5 || SampleSpeed > 50 ) {
    SetSeederror("Please fill the value between 5 to 50");
    datavals=1
    }
    else if(isNaN(SampleSpeed)){
      SetSeederror("Please fill the value between 5 to 50");
      datavals=1
    }
  }
    if(datavals!=1) {
      Setloading(true);
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}settings/Update_UXscore_setting/${UpdateId}/`,
            {
              user_domain_id: esodata.domaindata.value.header_selected,
              default_url: Url,
              sample_seed: SampleSpeed,
              page_load_time: PageLoadTime,
              tol_page_load_time: TotalPageLoadTime,
              apdex_score_metrics: Apdex,
            },

            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/errorpages");
            }
            // console.log("kannan",error)
          });
        setapiOpen(true);
        setapierrorMsg("You have successfully updated your settings.");
        Setloading(false);
      } catch (err) {
        Setloading(false);
        // toast.error('Something went wrong!', {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }
  };

  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if(userExpSettingP.status){
        GetUserExperienceSettings();
        faqdatafun();
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
  }, []);

  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if(userExpSettingP.status){
        var DomainList = esodata.domainlist.value;
        DomainList = DomainList.find(
          (obj) => obj.id == esodata.domaindata.value.header_selected
        );
        if (DomainList) {
          Seturl(DomainList.domain__url);
        }
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
  }, [esodata]);

  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    console.log(event.target.classList[0]);
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input" ||
      event.target.classList[0] === "run_rpt_btn" ||
      event.target.classList[0] === "input_col" ||
      event.target.classList[0] === "plagarism_analyzer_row" ||
      event.target.classList[0] === "MuiDataGrid-cell--withRenderer"
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=46`;

    try {
      const doaminsectiondatavalue = await axios
        .get(datavar, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
          // console.log("kannan",error)
        });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs);
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) {}
  };
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Convert to number and ensure value is within the valid range
    const numericValue = Number(value);

    if (value === '') {
      // Allow empty value for clearing
      SetSampleSpeed('');
    } else if (!isNaN(numericValue) && numericValue >= 5 && numericValue <= 50) {
      // Update state if value is valid
      SetSampleSpeed(numericValue);
    }
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Experience Score Settings</title>
      </Helmet>
      <div className={`over_all_container user_experieceover_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {loading === true ? <Loader /> : null}
        <div className="cnt_audit_outer_wrap">
          <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">User Experience Score Settings</h2>
              {/* <p>as of {jobDate}</p> */}
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? "faq_move_class" : ""}`}>
                <button
                  onClick={handleToggle}
                  className="faq_class"
                  title="Frequently Asked Question"
                >
                  <QuestionMarkIcon />
                </button>
                {toggle && (
                  <Rightsidebar
                    close={() => setToggle(false)}
                    initialfaqDateval={initialfaqDateval}
                  />
                )}
              </div>
              {/* <img src={pdficon} alt="pdf icon" />
              <img src={csvicon} alt="csv icon" /> */}
            </div>
          </div>
          <div className="link_cnt_hdr_blw_wrap">
            <Link to="/user-experience-setting">
              <button type="button" className={`site_rn_rpt_btn active`}>
                User Experience Settings
              </button>
            </Link>

            <Link to="/page-level-setting">
              <button type="button" className={`site_rn_rpt_btn`}>
                Page Level Settings
              </button>
            </Link>
          </div>
          {userExpSettingPlan.status ?
          <div className="user_experince_form_container_wrap">
            <div className="user_experince_form_container">
              <div className="form_row">
                <div className="form_column">
                  <div className="label">
                    <label>
                      Target Tolerable Time To Load Page<span>(s)</span>
                    </label>
                  </div>
                  <div className="input">
                    {" "}
                    {/* <input
                      type="number"
                      defaultValue={TotalPageLoadTime}
                      onChange={(e) => {
                        SetTotalPageLoadTime(e.target.value);
                      }}
                      value={TotalPageLoadTime}
                    /> */}
                    <input
  type="number"
  defaultValue={TotalPageLoadTime}
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && value <= 12) {
      SetTotalPageLoadTime(value);
    } else if (e.target.value === '') {
      SetTotalPageLoadTime('');
    } else {
      SetTotalPageLoadTime(TotalPageLoadTime);
    }
  }}
  value={TotalPageLoadTime}
/>
<div className="err user_experince_form_err">
                {totalpageerror}
              </div> 

            </div>
                  
                </div>
                <div className="form_column">
                  <div className="label">
                    <label>
                      Target Satisfactory Time To Load Page<span>(s)</span>
                    </label>
                  </div>
                  <div className="input">
                    {" "}
                    {/* <input
                      type="number"
                      defaultValue={PageLoadTime}
                      onChange={(e) => {
                        SetPageLoadTime(e.target.value);
                      }}
                      value={PageLoadTime}
                    /> */}
                    <input
  type="number"
  defaultValue={PageLoadTime}
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && value <= 12) {
      SetPageLoadTime(value);
    } else if (e.target.value === '') {
      SetPageLoadTime('');
    }
  }}
  value={PageLoadTime}
/>
<div className="err user_experince_form_err">
                {pageloaderror}
              </div> 
                  </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form_column">
                  <div className="label">
                    <label>
                      Random Sample Seed<span></span>
                    </label>
                  </div>
                  <div className="input">
                    {" "}
                    <input
                      type="text"
                      defaultValue={SampleSpeed}
                      onChange={(e) => {
                        SetSampleSpeed(e.target.value);
                      }}
                      value={SampleSpeed}
                    />
<div className="err user_experince_form_err">
                {seederror}
              </div> 

                  </div>
                </div>
                <div className="form_column">
                  <div className="label">
                    <label>Default UX Score URL</label>
                  </div>
                  <div className="input">
                    {" "}
                    <input
                      type="text"
                      defaultValue={Url}
                      onChange={(e) => {
                        Seturl(e.target.value);
                      }}
                      value={Url}
                    />
                      <div className="err user_experince_form_err">
                {uxscoreerror}
              </div>                    
                  </div>
                </div>
              </div>
              <div className="err user_experince_form_err">
                {UserExperienceSettingsErr}
              </div>
              <div className="user_experince_form_button">
                <button
                  className="run_rpt_btn rn_rpt_btn"
                  onClick={SaveSettings}
                >
                  Save
                </button>
              </div>
            </div>
          </div> : <Freeaudit planStatus={userExpSettingPlan} />
          }
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default UserExperienceSettings;
