import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import "./Performancestyle.css"
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import trueimages from "./../Asset/true-images.png";
import failimages from "./../Asset/fail-images.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import {datezonechange} from "./../Commonfun";


export default function Performancelist() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [value, onChange] = useState();
  const [valueOutside, onChangeOutsidde] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [initialDateval, SetInitialDateval] = useState([]);
  const [fataloutCount, setfataloutCount] = useState();
  const [erroroutCount, seterroroutCount] = useState();
  const [warningoutCount, setwarningoutCount] = useState();
  const [performancePlan, setperformancePlan] = useState({status: true, error: ""});

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;


  let detailurl = `${process.env.REACT_APP_DOMAIN}performance-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  const checkUserPlan = async () => {
    const performanceP = await Utils("performance", ApiToken);
    if(performanceP.status){
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
    }
    setperformancePlan(performanceP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);
  const columns = [

    {
      field: "serialid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 70,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "link__url",
      headerName: "Page URL",
      description: 'The URL of the web page to monitor usability, accessibility, UX, and performance under URL management settings.',
      minWidth: 350,
      flex: 1,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        // let firstEightLetters = cardData.row.link__url.substring(0, 30);
        // let lastThreeLetters = cardData.row.link__url.slice(-5); 
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let wholedata = "";
        if (cardData.row.link__url.length > 20) {
          firstEightLetters = cardData.row.link__url.substring(0, 20);
          lastThreeLetters = cardData.row.link__url.slice(-5);
          wholedata = firstEightLetters + '...' + lastThreeLetters;
        }
        else {
          firstEightLetters = cardData.row.link__url;
          wholedata = firstEightLetters;
        }

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a className="table_links" target="_blank" href={cardData.row.link__url}>{wholedata}</a>
              </div>

            </Tooltip>
          </>
        );

      },
    },
    {
      field: "UX_score",
      headerName: "UX Score",
      description: 'UX score for the specific URL or web page',
      minWidth: 85,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        return cardData.row.UX_score + "%"
      },
    },
    // {
    //   field: "Accessibility",
    //   headerName: "Accessibility",
    //   description:'Accessibility score for the URL or web page',
    //   width: 95,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "seo_impact",
      headerName: "Seo Impact",
      description: 'SEO Impact on the specific URL or web page',
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "responsiveness",
      headerName: "Responsiveness",
      description: 'Responsiveness of the URL or web page',
      minWidth: 125,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "url_check",
      headerName: "URL Check",
      description: 'URL Accessibility and friendliness check for the URL or web page',
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.url_check === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (

          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "html_element",
      headerName: "HTML Element",
      description: 'HTML Elements check for the URL or web page',
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.html_element === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "security",
      headerName: "Security",
      description: 'Secured HTTPS connection check',
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.security === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "amp",
      headerName: "AMP",
      description: 'AMP (Accelerated Mobile Pages) Compliance check',
      minWidth: 70,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.amp === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "content",
      headerName: "Content",
      description: 'Content metrics check for a URL or web page.',
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.content === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "performance",
      headerName: "Performance",
      description: 'Performance metrics check for a URL or web page.',
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.performance === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (

          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.link__id}` + "/" + `${jobidData}`}>
            <button className="buttonView">
              View Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
            {/* <img
              src={fav_icon}
              className="view_td_img"
              alt="view report icon"
            />  */}
          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "")
      jobid = jobidData;
    setLoading(true);
    setCardData([])
    try {


      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=performance&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        var Selectedmets = [];
        if (completevalues?.data) {
          console.log("completevalues", completevalues?.data?.jobs)
          completevalues?.data?.jobs.forEach((element) => {
            Selectedmets.push({
              id: element.id,
              completed_at: datezonechange(element.completed_at),
            });
          });
          console.log("Selectedmets", Selectedmets)
          SetInitialDateval(Selectedmets[0]);
          setSelectval(Selectedmets[0]?.id);
          SetdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets);
        }

      }
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (datavalue?.data) {
        const dataval = datavalue.data.table_data;
        var newData = "";
        if (dataval && dataval.length > 0) {
          SetAutoheightval(false)
          const newData = dataval.map((obj, index) => {
            return { ...obj, serialid: index + 1 };
          });
          setfataloutCount(datavalue?.data?.report_ux_score)
          setCardData(newData);

          setjobidData(datavalue?.data?.job_id)
          setSelectval(datavalue?.data?.job_id);
        }
        else {
          setCardData("");
          SetAutoheightval(true)

        }

      }
    }
    catch (err) {
      console.log('err', err)
    }
    setLoading(false);

  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
      setLoading(false);
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0]?.id);
    closeModal();
    var domainsec = "";
    console.log("siraj", selectval[0]?.id)
    callUserdataApi(selectval[0]?.id, domainsec = "");
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('performance')
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });
  const pdfdown = async () => {

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&domain_section_id=${Domainsectiondata}&job_id=${jobidData}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Performancelist", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&domain_section_id=${Domainsectiondata}&job_id=${jobidData}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Performancelist", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          // SetDomainsectiondata(Sections[0].value)
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue[0].value)
    callUserdataApi("", passvalue[0].value);
  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=19`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Performance </title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Performance </h2>
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              {performancePlan.status &&
                <div>
                  <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
                  <img src={csvicon} alt="csv icon" onClick={csvdown} />
                </div>
              }
            </div>

          </div>
        </div>

        {performancePlan.status ? 
        <div>
        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn" onClick={() => { SetRunReportPopShow(true) }}>
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
          {SectionOptions?.length > 0 &&
            <div className="listdropdown">
              <div>
                <span className="listdropdown_titl">Domain Section</span>

                <Select
                  className="drop-organicsearch"
                  name="name"
                  isClearable
                  onChange={SetSectionNameHandle}
                  options={SectionOptions}
                  isSearchable="true"
                  placeholder="Select Domain Section"
                />

              </div>
            </div>
          }
        </div>
        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h1></h1>
              <p></p>
            </div>
            <div className="cnt_hdr_content_box_outer">
              <div className="plagiarisam_missing_waring_wrap">
                <p> <b>{fataloutCount}</b></p>
                <p><b>Total UX Score</b></p>
              </div>


            </div>
          </div>
        </div>
        <div className="data_table" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },
                "& .MuiDataGrid-cell:first-child": {
                  position: "unset",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              // loading={loading}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          </div>
        </div>
        </div> : <Freeaudit planStatus={performancePlan} />
        }
      </div>
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      values={[dEngineoption]}
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values)
                        SetdEngineoption({
                          completed_at:
                            values[0]?.completed_at,
                          id:
                            values[0]?.id,
                        });
                      }}

                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>




      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </ div>
  );
}
