import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { Box } from "@mui/material";
import FastForwardIcon from '@mui/icons-material/FastForward';
import Stepperformhead from "./Stepperformhead";
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';



function Userdata() {
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const [JobId, SetJobId] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [CountryError, SetCountryError] = useState("");
  const [StateError, SetStateError] = useState("");
  const [CityError, SetCityError] = useState("");
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  // const [dEngineoption, setdEngineoption] = useState({

  // })

  const dispatch = useDispatch()
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const ApiToken = esodata.apiauthkey.value;

  useEffect(() => {
    callUserdataApi();
  }, []);
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const finalreportNow = async () => {
    setLoading(true);
    SetCountryError("")
    SetStateError("")
    SetCityError("")
    if (Country === "") {
      SetCountryError("Select Country")
      return false;
    }
    if (State === "") {
      SetStateError("Select State")
      return false;
    }
    if (City === "") {
      SetCityError("Select City")
      return false;
    }
    if (Country !== "" && State !== "" && City !== "") {
      SetCityError("")
      setLoading(true);
      // localStorage.setItem("Country",Country);
      // localStorage.setItem("State",State);
      // localStorage.setItem("City",City.value);
      try {
        const userbasicData = await axios.get(
          `${process.env.REACT_APP_API_URL}accounts/save_user_location/?country_id=${Country.value}&state_id=${State.value}&city_id=${City.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        if (userbasicData?.status == 200) {
          if (userbasicData?.data?.on_boarding_status == 1) {
            navigate('/')
          }
          else {
            navigate('/Domaindata')
          }
        }
        else {

        }

      }
      catch (err) { }
    }
    setLoading(false);
  };
  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    if (value) {
      SetCountry(value);
      SetState("");
      SetCity("");
      localStorage.setItem("dCountry", JSON.stringify(value))

      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${value.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        if (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element.state_name,
                value: element.id,
              });
            });
            SetStateoptions(Sections);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    if (value) {
      SetState(value);
      SetCity("");
      localStorage.setItem("dState", JSON.stringify(value));
      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${value.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (citydatavalue?.data) {
        if (citydatavalue?.data?.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({
              label: element.city_name,
              value: element.id,
            });
          });
          SetCityOptions(Sections);
        }
      }
    }
  };

  const SetCityHandle = async (value) => {
    if (value) {
      SetCity(value);
      localStorage.setItem("dCity", JSON.stringify(value));
    }
  }

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }
    setLoading(false);
  };

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {

        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        // localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      // localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };


const checkfunct =async()=>{
  if (JSON.parse(localStorage.getItem("dCountry"))) {
    SetCountry(JSON.parse(localStorage.getItem("dCountry")))

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${JSON.parse(localStorage.getItem("dCountry")).value}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({
            label: element.state_name,
            value: element.id,
          });
        });
        SetStateoptions(Sections);
      }
    }

  }

  if (JSON.parse(localStorage.getItem("dState"))) {
    SetState(JSON.parse(localStorage.getItem("dState")))
    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${JSON.parse(localStorage.getItem("dState")).value}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({
            label: element.city_name,
            value: element.id,
          });
        });
        SetCityOptions(Sections);
      }
    }

  }

  if (JSON.parse(localStorage.getItem("dCity"))) {
    SetCity(JSON.parse(localStorage.getItem("dCity")))
  }
}


  useEffect(() => {

    checkfunct();

  }, [])


  return (
    <div className="userdata addrs">
      <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px", background: "#fff", position: "fixed", zIndex: 999 }}>
        {/* Logo at the start */}
        <img
          className="Eso_logo"
          src={logoimage}
          alt="logo"
          style={{ width: "14%", background: "#fff" }}
        />

        {/* Group for the two buttons */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingRight: "20px" }}>
          <button
            onClick={handleToggle}
            className="faq_class"
            title="Frequently Asked Question"
            style={{ padding: "8px 13px 8px 7px" }}
          >
            <QuestionMarkIcon />
          </button>

          {/* FAQ Sidebar conditionally rendered */}
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

          <button onClick={TriggerLogout}>
            <span
              className="material-icons"
              style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
            >
              <LogoutIcon />
            </span>
          </button>
        </div>
      </div>
      <Stepperformhead setActiveStep={0} />

      <div className="outer-container"
        style={{
          width: "calc(100% - 60px)",
          marginRight: "60px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="userdata_inner">
          <h1>Please Share Your Location Details</h1>
          <p>This Helps Us Customize Your Experience</p>
          <form className='frm_container'>
            <div className="form-group user_country_data">
              <label for="country" style={{ display: "flex", fontWeight: "700" }}>Country:</label>
              <div className="form-inner-data">
                <Select
                  name="Country"
                  // values={JSON.parse(localStorage.getItem("dCountry")) || JSON.parse(localStorage.getItem("dCountry")) != undefined ? [JSON.parse(localStorage.getItem("dCountry"))].filter(Boolean) : []}
                  value={Country}
                  className="userdataselect"
                  classNamePrefix="userdataselect"
                  isSearchable
                  placeholder="Enter Your Country"
                  options={Countryoptions}
                  onChange={SetCountryHandle}
                />
                <span className="error-value" style={{ display: "flex" }}>{CountryError}</span>
              </div>
            </div>
            <div class="form-group user_country_data user_state">
              <label for="state" style={{ display: "flex", fontWeight: "700" }}>State:</label>
              <div className="form-inner-data">
                <Select
                  name="State"
                  // values={JSON.parse(localStorage.getItem("dState")) || JSON.parse(localStorage.getItem("dState")) != undefined ? [JSON.parse(localStorage.getItem("dState"))].filter(Boolean) : []}
                  value={State}
                  className="userdataselect"
                  classNamePrefix="userdataselect"
                  placeholder="Enter Your State"
                  isSearchable
                  options={Stateoptions}
                  onChange={SetStateHandle}
                />
                <span className="error-value" style={{ display: "flex" }}>{StateError}</span>
              </div>
            </div>
            <div class="form-group user_country_data user_city">
              <label for="city" style={{ display: "flex", fontWeight: "700" }}>City:</label>
              <div className="form-inner-data">
                <Select
                  name="City"
                  // values={localStorage.getItem("dCity") || localStorage.getItem("dCity") != undefined ? [JSON.parse(localStorage.getItem("dCity"))].filter(Boolean) : []}
                  value={City}
                  className="userdataselect"
                  classNamePrefix="userdataselect"
                  placeholder="Enter Your City"
                  isSearchable
                  options={CityOptions}
                  onChange={SetCityHandle}
                />
                <span className="error-value" style={{ display: "flex" }}>{CityError}</span>
              </div>
            </div>
            {/* <div className="form_next div_next_button">
              <button className="nextbutton" onClick={finalreportNow} type="button">Next</button>
            </div> */}
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <Button
                className="steppersubmitform"
                variant="contained"
                color="primary"
                onClick={finalreportNow}
                disabled={!Country || !State || !City}
                sx={{
                  padding: "10px 15px",
                  borderRadius: "10px",
                  // fontWeight: "900",
                  fontSize: "16px",
                  backgroundColor: !Country || !State || !City ? "#ccc" : "#ff601f",
                  color: !Country || !State || !City ? "#666" : "white",
                  "&:hover": {
                    backgroundColor: !Country || !State || !City ? "#ccc" : "#ff7d45",
                  },
                }}
              >
                Next <FastForwardIcon />

              </Button>
            </Box>
          </form>
        </div>
      </div>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}
export default Userdata;