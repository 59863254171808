import React, { useState, useEffect } from "react";
import "./Siteauditstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import Allpagecrawl from "./onpage/Allpagecrawl"
import Missingtitle from "./onpage/Missingtitle";
import Duplicatetitle from "./onpage/Duplicatetitle";
import Missingcanonical from "./onpage/Missingcanonical";
import Duplicatedescription from "./onpage/Duplicatedescription";
import H1table from "./onpage/H1table";
import Paginationnext from "./onpage/Paginationnext";
import Hreflang from "./onpage/Hreflang";
import ImageData from "./onpage/ImageData";
import Missingalt from "./onpage/Missingalt";  
import Tagsh1table from "./onpage/Tagsh1table";  
import Dialog from "@mui/material/Dialog";   
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import { Tooltip } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { GetOnPageJobIds } from "../../../Store/OnpageJobId";
import Loader from "../CommonLoader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from 'react-router-dom';

// import Missingalt from "./onpage/Missingalt";

export default function Onpagecrawllist(props) {
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
 const [butval,setButval]=useState("1")
 const [secbutval,setSecbutval]=useState("1")
  const [butvalue, setButvalue] = useState("1");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl,SetDoaminSectionUrl]=useState([])
  const [Domainsectiondata,SetDomainsectiondata]=useState([])
  const [jobidData, setjobidData] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = React.useState(false)
  const [reportTabs, setReportTabs] = useState({})
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [buttonShowdata,SetbuttonShowdata]=useState([])
  
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
      id:
      'Select Date',
  });
  let querystring = useParams();
  let domain_id = querystring.id;
  let on_domain_id = querystring.onid;
  const navigate = useNavigate();
const  handleEdit = e => {
    e.preventDefault();
    buttonFilterval(Domainsectiondata,JobId)
    setIsButtonDisabled(true);
    // alert(e.target.id)
    props.setFielddata(e.target.id)
    setButvalue(e.target.id)
    setButval(e.target.id)
    setSecbutval(e.target.value)
    // setTimeout(() => {
      setIsButtonDisabled(false);
    // }, 500); 
  };
const renderSwitch = (params) =>{
 
  switch(params){
    case "1":
      return <Allpagecrawl JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "2":
      return <Missingtitle JobId={JobId}  datavalue={secbutval} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "3":
      return <Missingcanonical JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "4":
      return <Duplicatetitle JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "5":
      return <Duplicatedescription JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "6":
      return <ImageData datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "7":
      return <H1table datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "8":
      return <Paginationnext JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "9":
      return <Hreflang JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "14":
      return <Missingtitle datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "15":
      return <Missingtitle datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "16":
      return <Missingtitle datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "17":
      return <Missingtitle datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "18":
      return <Missingalt datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "19":
      return <H1table datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
    case "20":
      return <Tagsh1table datavalue={secbutval} JobId={JobId} SubmitTriggered={SubmitTriggered} SetSubmitTriggered={SetSubmitTriggered} domainsection={Domainsectiondata}/>
  }
 

}

 //filter Add start

 const dispatch = useDispatch();
  
//  const esodata = useSelector((state) => state);

 const [JobId,SetJobId]=useState('')


 const CloseModalTrigger=()=>{

  // SetJobId('')
   props.setIsOpen(false)
 }

 const JdomainvaldataChange=(value)=>{
   SetJobId(value[0].value)

 }

 const [SubmitTriggered,SetSubmitTriggered]=useState(false)

 const [validation, setValidation] = useState({
  dateerror: "",
});

 const FetchCompetitorList=()=>{


  var errs = 1;
  let errors = {};

  if (JobId === "") {
    errors.dateerror = "This field is required.";
    errs = 2;
  } else {
    errors.dateerror = "";
    errs = 1;
    setValidation(errors);
  }
  if (errs === 2) {
    return setValidation(errors);
  }
   if(JobId!=='')
   {
   
    SetSubmitTriggered(true)
     props.setIsOpen(false)
     if (props.setJobdateval) 
     props.setJobdateval(JobId)
    //  SetJobId('')
    buttonFilterval(Domainsectiondata,JobId)
   }

 }
 const buttonFilterval = async (domainsecval="" ,jobidval="") => {
  try {
    const doamindatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/on_page_crawl_status/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsecval}&job_id=${jobidval}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    );
    setReportTabs(doamindatavalue?.data?.report_tabs)
    props.setJobdate(moment(doamindatavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));    

  } catch (err) {
    setReportTabs({})
    console.error("Error in API call:", err);
  }
};

 const LoadAddFormFilterOptions = async () => {
  SetSectionOptions([]);
  try {
    const doaminsectiondatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    );

    if (doaminsectiondatavalue.data) {
      if (doaminsectiondatavalue.data.domain_sections) {
        SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
        var Sections = [];
        doaminsectiondatavalue.data.domain_sections.forEach((element) => {
          Sections.push({ label: element.name, value: element.id });
        });
        console.log(Sections)
        SetSectionOptions(Sections);
      }
    }
  } catch (err) {}
  if (jobidData === "") {
    const completevalues = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        const datavalue = "";
      });
      if(completevalues?.data?.jobs.length>0)
      {
    setcompletedate(completevalues?.data?.jobs);
    SetJobId(completevalues?.data?.jobs[0].id);
    SetdEngineoption(completevalues?.data?.jobs[0]);
  }
      
  }
}
const onPagecrawllist = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}technical/on_page_crawl_filter/?user_domain_id=${esodata.domaindata.value.header_selected}`
      , {
      headers: {
        Authorization: `${ApiToken}`,
      },
    });
    // Handle successful response
    const domainsectiondatavalue = response.data; // Rename as needed
    SetbuttonShowdata(domainsectiondatavalue.report_tabs); // Process the data or set state here
    
  } catch (error) {
    if (
      [599, 500, 400, 404].includes(error?.response?.status)
    ) {
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error || "An error occurred");
    } else if (error?.code === "ERR_NETWORK") {
      navigate('/errorpages');
    } else {
      console.error("Unhandled error:", error);
    }
  }
};

 useEffect(() => {
  // alert(on_domain_id)
  onPagecrawllist()
  buttonFilterval("",JobId)
  props.setFielddata(on_domain_id)
  LoadAddFormFilterOptions()
  setButvalue(on_domain_id)
  setButval(on_domain_id)
  setSecbutval(on_domain_id)
  if(on_domain_id==2)
  setSecbutval(1)
  if(on_domain_id==6)
  setSecbutval(11)
  if(on_domain_id==18)
  setSecbutval(11)
  if(on_domain_id==7)
  setSecbutval(6)
  if(on_domain_id==19)
  setSecbutval(7)
  if(on_domain_id==20)
  setSecbutval(8)
  if(on_domain_id==16)
  setSecbutval(5)
  if(on_domain_id==17)
  setSecbutval(10)
  if(on_domain_id==14)
  setSecbutval(2)
  if(on_domain_id==15)
  setSecbutval(3)
  if(on_domain_id==18)
  setSecbutval(12)
  CloseModalTrigger()
  dispatch(GetOnPageJobIds(esodata.domaindata.value.header_selected));
}, []);

const SetSectionNameHandle=(passvalue)=>{
  
  SetDomainsectiondata(passvalue[0]?.value)
  buttonFilterval(passvalue[0]?.value,JobId)
}



 //filter add end
  return (
    <>
        {loading === true ? <Loader /> : null}
      <div className="listdropdown_drop_site_onpage" style={{"display":"flex","margin-left":"40px",marginTop:25}}>
            <div>
                  <span className="listdropdown_titl">Domain Section</span>
                      {SectionOptions?.length > 0 &&
                     <Select
                  className="drop-organicsearch"
                  name="name"
                  isClearable
                  onChange={SetSectionNameHandle}
                  options={SectionOptions}
                  isSearchable="true"
                   placeholder="Select Domain Section"
                   />
                      }
                </div>
              </div>
              <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
   <div className="site_table_blw_wrap">
  <div className="cnt_hdr_blw_wrap site_content_wrap_butn">
    <div className="button_row">
      {/* First Row of 4 Buttons */}
      <div className="button_container">
      { buttonShowdata.includes("All") &&(
        <Tooltip title={"Error Count - " + reportTabs?.all}>
        <button
          id={1}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "1" ? "onpage_button_active" : ""} {reportTabs?.all == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          All
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Missing Titles") &&(
          <Tooltip title={'Error Count - ' + reportTabs?.missing_title}>
        <button
          id={2}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={1}
          className={`${butvalue === "2" ? "onpage_button_active" : ""} ${reportTabs?.missing_title == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Missing<br />Title
        </button>
        </Tooltip>)}
       { buttonShowdata.includes("Missing Canonical") &&(
        <Tooltip title={'Error Count - ' + reportTabs?.missing_canonical}>
        <button
          id={14}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={2}
          className={`${butvalue === "14" ? "onpage_button_active" : ""} ${reportTabs?.missing_canonical == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Missing<br />Canonical
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Missing Descriptions") &&( 
                  <Tooltip title={'Error Count - ' + reportTabs?.missing_description}>
          <button
          id={15}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={3}
          className={`${butvalue === "15" ? "onpage_button_active" : ""} ${reportTabs?.missing_description == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Missing<br />Description
        </button> 
        </Tooltip>)}
      </div>

      {/* Second Row of 4 Buttons */}
      <div className="button_container">
      { buttonShowdata.includes("Mismatch Canonical") &&( 
                <Tooltip title={'Error Count - ' + reportTabs?.mismatch_canonical}>
        <button
          id={3}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "3" ? "onpage_button_active" : ""} ${reportTabs?.mismatch_canonical == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Mismatch<br />Canonical
        </button> 
        </Tooltip>)}
        { buttonShowdata.includes("Duplicate Titles") &&( 
                  <Tooltip title={'Error Count - ' + reportTabs?.duplicate_title}>
          <button
          id={4}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "4" ? "onpage_button_active" : ""} ${reportTabs?.duplicate_title == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >        Duplicate<br />Title
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Duplicate Descriptions") &&(
                  <Tooltip title={'Error Count - ' + reportTabs?.duplicate_description}>
          <button
          id={5}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "5" ? "onpage_button_active" : ""} ${reportTabs?.duplicate_description == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Duplicate<br />Description
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Images") &&(
                  <Tooltip title={'Images Count - ' + reportTabs?.images}>
          <button
          id={6}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={11}
          className={`${butvalue === "6" ? "onpage_button_active" : ""} ${reportTabs?.images == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Images
        </button>
        </Tooltip>)}
      </div>

      {/* Third Row of 4 Buttons */}
      <div className="button_container">
      { buttonShowdata.includes("Missing Alt Tags") &&(
                <Tooltip title={'Error Count - ' + reportTabs?.missing_alt}>
        <button
          id={18}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={12}
          className={`${butvalue === "18" ? "onpage_button_active" : ""} ${reportTabs?.missing_alt == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Missing<br />Alt
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Missing H1 Tags") &&(
                  <Tooltip title={'Error Count - ' + reportTabs?.missing_h1}>
          <button
          id={16}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={5}
          className={`${butvalue === "16" ? "onpage_button_active" : ""} ${reportTabs?.missing_h1 == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Missing<br />H1
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Duplicate H1 Tags") &&(
                  <Tooltip title={'Error Count - ' + reportTabs?.duplicate_h1}>
                    <button
          id={7}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={6}
          className={`${butvalue === "7" ? "onpage_button_active" : ""} ${reportTabs?.duplicate_h1 == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Duplicate<br />H1
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Multiple H1 Tags") &&(
                  <Tooltip title={'Error Count - ' + reportTabs?.multiple_h1}>
        <button
          id={19}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={7}
          className={`${butvalue === "19" ? "onpage_button_active" : ""} ${reportTabs?.multiple_h1 == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Multiple<br />H1
        </button>
        </Tooltip>)}
      </div>

      {/* Fourth Row of 4 Buttons */}
      <div className="button_container">
      { buttonShowdata.includes("H1 Tags Available") &&( 
        <Tooltip title={'H1 Tags Available Count - ' + reportTabs?.h1_tags_available}>
        <button
          id={20}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={8}
          className={`${butvalue === "20" ? "onpage_button_active" : ""} ${reportTabs?.h1_tags_available == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          H1 Tags<br />Available
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("No Index Robot") &&(
                  <Tooltip title={'Error Count - ' + reportTabs?.no_index_robot}>
        <button
          id={17}
          type="button"
          onClick={(e) => handleEdit(e)}
          value={10}
          className={`${butvalue === "17" ? "onpage_button_active" : ""} ${reportTabs?.no_index_robot == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          No-index<br />robot
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Pagination Prev Or Next") &&(
                  <Tooltip title={'Pagination Prev Or Next Count - ' + reportTabs?.pagination_prev_or_next}>
        <button
          id={8}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "8" ? "onpage_button_active" : ""} ${reportTabs?.pagination_prev_or_next == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Pagination<br />Next or<br />Prev
        </button>
        </Tooltip>)}
        { buttonShowdata.includes("Hreflang") &&(
        <Tooltip title={'Error Count - ' + reportTabs?.hreflang}>
        <button
          id={9}
          type="button"
          onClick={(e) => handleEdit(e)}
          className={`${butvalue === "9" ? "onpage_button_active" : ""} ${reportTabs?.hreflang == 0  ? "onpage_highlight" : ""}`}
          disabled={isButtonDisabled}
        >
          Hreflang
        </button>
        </Tooltip>)}
      </div>
    </div>
  </div>
</div>

</div>

      {renderSwitch(butval)}

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
            
            <div>
             
              <div className="">
                <div>
                  <span>Completed Date</span>
                  <Select
                    values={[dEngineoption]}
                    // placeholder="Select Date"
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => {SetJobId(values[0].id)
                      SetdEngineoption({
                        completed_at:
                          values[0].completed_at,
                          id:
                          values[0].id,
                      });                    
                    }}
                
                    />
                 
                </div>
           
               
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
              
              </div>
           
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{cursor:'pointer'}} >
            Submit
          </button>
          <button style={{cursor:'pointer'}} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      </>
  );
}
