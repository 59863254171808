import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';

function Stepperformhead(props) {
    const steps = [
        "USER DETAILS",
        "DOMAIN DETAILS",
        "DOMAIN OWNERSHIP VERIFICATION",
        "GOOGLE ANALYTICS",
    ];

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (props.setActiveStep !== undefined) {
            setActiveStep(props.setActiveStep);
        }
    }, [props.setActiveStep]);


    return (
        <Box
            className="stepperFormclass"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "66px",
                marginBottom: "10px",
                flexWrap: "wrap", // Wrap items on smaller screens
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                padding: "40px 0px"
            }}
        >
            {steps.map((label, index) => (
                <React.Fragment key={index}>
                    {/* Step labels */}
                    <Box
                        sx={(theme) => ({
                            padding: "1px 32px",
                            backgroundColor: activeStep >= index ? "#FF601F" : "white", // Active steps (current and previous) in orange
                            color: activeStep >= index ? "white" : "black", // Text color for active (white) and inactive (black)
                            borderRadius: "20px",
                            fontWeight: activeStep >= index ? "bold" : "normal", // Bold for active steps
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 8px",
                            cursor: "auto",
                            border: activeStep >= index ? "none" : "1px solid #ddd",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            textAlign: "center",
                            height: "40px",
                            zIndex: 1,
                            gap:"10px",
                            // Responsive styles
                            fontSize: "14px",
                            [theme.breakpoints.down("sm")]: {
                                padding: "1px 16px",
                                fontSize: "12px",
                                margin: "4px",
                            },
                        })}
                    // onClick={() => setActiveStep(index)} // Uncomment if steps should be clickable
                    >
                        {label}
                    </Box>

                    {/* Dotted line between steps */}
                    {index < steps.length - 1 && (
                        <Box
                            sx={(theme) => ({
                                mx: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderBottom: activeStep > index ? "3px solid #FF601F" : "3px dotted #ddd", // Solid line for completed steps
                                width: "30px",
                                height: "2px",
                                zIndex: 0,
                                marginLeft: "0px",
                                marginRight: "0px",
                                // Responsive styles
                                [theme.breakpoints.down("sm")]: {
                                    width: "20px",
                                },
                            })}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    )
}

export default Stepperformhead