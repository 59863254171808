import React, { useState, useEffect } from "react";
import ChainIcon from "../../../Icons/chain.png";
import BrokenIcon from "../../../Icons/broken-link.png";
import BrowseImageIcon from "../../../Icons/browse-images.png";
import ExternalLinkIcon from "../../../Icons/external-link.png";
import ChainWhiteIcon from "../../../Icons/chain-white.png";
import BrokenWhiteIcon from "../../../Icons/broken-link-white.png";
import BrowseImageWhiteIcon from "../../../Icons/browse-images-white.png";
import ExternalLinkWhiteIcon from "../../../Icons/external-link-white.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "react-dropdown-select";
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from "react-router-dom";

const ExternalLinkListData = (props) => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const [TotalExternalLinks, SetTotalExternalLinks] = useState(0)
  const [InvalidLinks, SetInvalidLinks] = useState(0)
  const [ImageVideoLinks, SetImageVideoLinks] = useState(0)
  const [RootDomainsLinks, SetRootDomainsLinks] = useState(0)
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();

  const [BrowseImageIcons, setBrowseImageIcons] = useState(BrowseImageIcon);
  const [ChainIcons, setChainIcons] = useState(ChainIcon);
  const [BrokenIcons, setBrokenIcons] = useState(BrokenIcon);
  const [ExternalLinkIcons, setExternalLinkIcons] = useState(ExternalLinkIcon);

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });
  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "url",
      headerName: "URL",
      minWidth: 450,
      flex: 1,
      headerAlign: "center",
      align: "center",
      description: "External web page URL of the domain",
      renderCell: (cardData) => {

        let firstEightLetters = cardData.row.url.substring(0, 30);
        let lastThreeLetters = cardData.row.url.slice(-5);

        return (
          <>
            <Tooltip title={cardData?.row?.url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a className="table_links" target="_blank" href={cardData?.row?.url}>{cardData?.row?.url}</a>
              </div>

            </Tooltip>
          </>
        );

      },
    },
    {
      field: "external_url",
      headerName: "External URL",
      description: 'External links are present on the web page.',
      minWidth: 450,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        let firstEightLetters = cardData.row.external_url.substring(0, 40);
        let lastThreeLetters = cardData.row.external_url.slice(-5);

        return (
          <>
            <Tooltip title={cardData?.row?.external_url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a className="table_links" target="_blank" href={cardData?.row?.external_url}>{cardData?.row?.external_url}</a>
              </div>

            </Tooltip>
          </>
        );

      },
    },
    {
      field: "content_type",
      headerName: "Content Type",
      description: 'It displays the content type of the external link',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "anchor_text",
      headerName: "Anchor Text",
      description: 'Anchor text used in the web page for linking the external link',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "status_code",
      headerName: "Status Code",
      description: "Codes are generated by the server in response to the client's request.",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const FetchExternalLinkList = async (jobid = '') => {
    setLoading(true)
    setCardData([]);
    SetTotalExternalLinks(0)
    SetInvalidLinks(0)
    SetImageVideoLinks(0)
    SetRootDomainsLinks(0)

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/external_links/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.links_data) {
          SetAutoheightval(false)
          const newData = datavalue?.data?.links_data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          console.log("newDatanewData", newData)
          setCardData(newData);
        }
        setLoading(false);
      }
      else {
        setCardData("");
        SetAutoheightval(true)
        setLoading(false);
      }

      props.SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

    } catch (err) {
      console.log('err', err)
    }





    try {

      const datavaluecard = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/external_links_count/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavaluecard?.data) {
        SetTotalExternalLinks(datavaluecard?.data?.total_external_url_count)
        SetInvalidLinks(datavaluecard?.data?.total_invalid_links)
        SetImageVideoLinks(datavaluecard?.data?.total_image_and_video_links)
        SetRootDomainsLinks(datavaluecard?.data?.total_root_domains_linked)
      }

    } catch (err) {
      console.log('err', err)
    }


    CloseModalTrigger()
    setLoading(false)
  };


  const CloseModalTrigger = () => {
    var errors = {}
    setValidation(errors)
    props.setIsOpen(false)
  }

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    FetchExternalLinkList(selectval)
  }

  const FetchJobDates = async () => {


    try {

      const completevalues = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=link_checker&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (completevalues?.data?.jobs.length > 0) {
        setcompletedate(completevalues?.data?.jobs)
        setSelectval(completevalues?.data?.jobs[0].id)
        SetdEngineoption(completevalues?.data?.jobs[0])
      }

    } catch (err) {
      console.log('err', err)
    }






  }
  const apihandleClose = () => {

    setapiOpen(false);
  };


  useEffect(() => {
    FetchExternalLinkList();
    FetchJobDates()
  }, []);
  return (
    <>
      {
        loading === true ? <Loader /> : null
      }
      <div className="external_link_data_row">
        <div className="cols"
          onMouseEnter={() => { setChainIcons(ChainWhiteIcon) }}
          onMouseLeave={() => { setChainIcons(ChainIcon) }}
        >
          <div className="icon_col">
            <img className="external_data_val_rank" src={ChainIcons}   style={{ pointerEvents: 'none' }}/>
          </div>
          <div className="content_col">
            <p className="external_data_val_rank">Total External Links</p>
            <p className="external_data_val_rank">{TotalExternalLinks}</p>
          </div>
        </div>
        <div className="cols"
          onMouseEnter={() => { setBrokenIcons(BrokenWhiteIcon) }}
          onMouseLeave={() => { setBrokenIcons(BrokenIcon) }}
        >
          <div className="icon_col">
            <img className="external_data_val_rank" src={BrokenIcons}
              alt="Icon"   style={{ pointerEvents: 'none' }} />
          </div>
          <div className="content_col">
            <p className="external_data_val_rank">Invalid Links</p>
            <p className="external_data_val_rank">{InvalidLinks}</p>
          </div>
        </div>
        <div className="cols"
          onMouseEnter={() => { setBrowseImageIcons(BrowseImageWhiteIcon) }}
          onMouseLeave={() => { setBrowseImageIcons(BrowseImageIcon) }}
        >
          <div className="icon_col">
            <img className="external_data_val_rank" src={BrowseImageIcons} style={{ pointerEvents: 'none' }}/>
          </div>
          <div className="content_col">
            <p className="external_data_val_rank">Image/Video Links</p>
            <p className="external_data_val_rank">{ImageVideoLinks}</p>
          </div>
        </div>
        <div className="cols"
          onMouseEnter={() => { setExternalLinkIcons(ExternalLinkWhiteIcon) }}
          onMouseLeave={() => { setExternalLinkIcons(ExternalLinkIcon) }}
        >
          <div className="icon_col">
            <img className="external_data_val_rank" src={ExternalLinkIcons}   style={{ pointerEvents: 'none' }} />
          </div>
          <div className="content_col">
            <p className="external_data_val_rank">Root Domains Linked</p>
            <p className="external_data_val_rank">{RootDomainsLinks}</p>
          </div>
        </div>
      </div>

      <div className="external_link_table_row">
        <div className="data_table external_link_checker_table" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            {/* <DataGrid
              // getRowId={(row) => generateRandom()}
               gridRowId={(row) => row.sid}
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay
              }}
              slotProps={{
                toolbar: {
              
                  showQuickFilter: true,
                
                },
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },
                "& .MuiDataGrid-cell:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
              }}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval?true:false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              loading={loading}
            /> */}
            <DataGrid
              getRowId={(row) => row.sid}
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                '&, [class^=MuiDataGrid]': { border: 'none' },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },
                "& .MuiDataGrid-cell:first-child": {
                  position: "unset",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              getRowHeight={() => 'auto'}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              // loading={loading}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      values={[dEngineoption]}
                      placeholder="Select Date"
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values[0].id)
                        console.log("values", values)
                        SetdEngineoption({
                          completed_at:
                            values[0].completed_at,
                          id:
                            values[0].id,
                        });


                      }}

                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExternalLinkListData;
